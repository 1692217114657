.card{
	.item-edit{
		.fa{
		    color: $cfourSecondaryColor;
			cursor: pointer;
		    &:hover{
				color: $cfourSecondaryColor;
				border-color: $cfourSecondaryColor;
	    	}
    	}
	}
}