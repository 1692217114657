html .sidebar-categories {
  .category-title {
    /*color: #062e47;*/
    font-size: 1.2rem;
    padding-left: 1.2rem;
  }

  & > ul {
    & > li.active {
      & > a {
        & > .collapse > ul > li.active > a {
          background: #efefef !important;
        }
      }
    }
    & > .nav-item {
      & > .nav-link {
        padding: 1rem 1rem 1rem 1.2rem;
      }
      & > .collapse {
        & > ul > li {
          & > a {
            padding: 1rem 1rem 1rem 2rem;
          }
          &.active {
            & > a {
              background: $cfourSecondaryColor;
              color: $cfourWhite;
            }
          }

           & > .collapse > ul > li {
             & > a {
               padding: 1rem 1rem 1rem 3rem !important;
             }

             &.active {
               & > a {
                 background: $cfourSecondaryColor;
                 color: $cfourWhite;
               }
             }

             & > .collapse > ul > li {
               & > a {
                 padding: 1rem 1rem 1rem 4rem !important;
               }
             }
           }
        }
      }
    }
  }
  ul {

    li {
      a {
        padding: 1.05rem 15px;
        &.toggle {
          position: absolute;
          background: 0 0!important;
          height: 35px;
          display: block;
          vertical-align: middle;
          //right: 20px;
          right: 5px;
          padding: 1rem 15px .75rem !important;
          margin-top: -48px !important;
          border: none;
        }
        &:hover {
          color: #000 !important;
        }
      }
      /*&.active {
        & > a {
          background: #efefef !important;
        }
      }*/
      ul {
        display: block;
        li {
          a {
            //border-top: 1px solid #efefef;
            /*color: #ababab;*/
          }
          &.active {
            & > a {
              /*color: #000 !important;*/
              .fa {
                color: $cfourWhite;
              }
            }
          }
          .collapse.show {
            ul {
              li {
                a {
                  padding: 1rem 0 !important;
                  /*margin-left: 21px;*/
                  /*color: #062e47;*/
                  @media (min-width: 992px) {
                    /*max-width: 91%;*/
                  }
                  &.toggle {
                    margin-top: -48px;
                    /*margin-right: 15px;*/
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/*p.sidebar-menu-title {
  padding: 15px;
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: $cfourWhite;
}*/
