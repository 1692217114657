body {
  background-color: $cfourWhite;
  color: $cfourPrimaryColor;


  a {
    outline: none;
    color: $cfourTertiaryColor;

    &:hover {
      color: $cfourPrimaryColor;
    }
  }

  a.text-primary {
    outline: none;
    color: $cfourTertiaryColor !important;

    &:hover {
      color: $cfourPrimaryColor !important;
    }
  }

  .form-control:focus {
    border-color: $cfourTertiaryColor;
  }
}

.c4brands {
  width: 100%;
  .c4brandsimages.c4grayscalebrands img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .c4brandsimages {
    .c4brandlogo {
      position: relative;
      height: 150px;
      margin-bottom: 20px;
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .c4textafterbrands {
    margin-top: 20px;
  }
}
