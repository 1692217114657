@import "_variables";
@import "_breakpoints";

// global
@import "ceres/legacy";
@import "ceres/common/card";
@import "ceres/common/common";
@import "ceres/common/buttons";
@import "ceres/common/structure";

// Page Design / Global components
@import "ceres/views/Item/single-item";
@import "ceres/views/PageDesign/header";
@import "ceres/views/PageDesign/footer";
@import "ceres/views/PageDesign/navigation";
@import "ceres/views/PageDesign/search-box";
@import "ceres/views/PageDesign/language-select";
@import "ceres/views/PageDesign/breadcrumb";
@import "ceres/views/PageDesign/sidebar";
@import "ceres/views/Templates/CategoryListItem/category-list-item";

// External plugins overrides
@import "ceres/plugins/owl-carousel";

// Components
@import "ceres/components/featured-category";
@import "ceres/components/featured-sale-new";
@import "ceres/components/anicon";

// Templates
@import "ceres/views/Templates/AddressSelect/address-select";
@import "ceres/views/Templates/ItemListSort/pagination";

// Pages
@import "ceres/views/ItemList/loading-animation";
@import "ceres/views/Home/home";

@font-face {
    font-family: "Roboto-Light";
    src: url("../fonts/Roboto-Light.ttf");
}
