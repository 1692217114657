
header .navbar .container-max > .row {
  flex-direction: row;
}
html nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li ul.nav-dropdown-inner {
  background: transparent;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}
.list-controls .btn-link {
  color: #fff !important;
}
.no-touch nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li:hover, .touch nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li.hover {
  background: #fff !important;
  color: #004157 !important;
}

nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li ul.nav-dropdown-inner .level1 > a {
  color: #3e382c;
  font-weight: bold;
}
nav.navbar.megamenu .main-navbar-collapsable ul.mainmenu > li > ul.collapse > li a {
  border-top: 3px solid #fff;
  border-bottom: 2px solid #fff;
  display: block;
}
.main-navbar-collapsable ul.mainmenu li > ul > li:hover > a {
  border-color: #3e382c !important;
  /* color: #062e47; */
}
html .add-item .fa {
  color: inherit;
  border-color: inherit;
}
.cmp-address-list .item-content.small {
  color: inherit;
}

.cmp-address-list .dropdown-menu.show {
  transform: translate3d(0px, 114px, 0px) !important;
}

#page-header-parent > .container-max,
#page-header-parent > .navbar,
#page-header-parent > .unfixed {
  top: 0 !important;
  position: inherit !important;
}

#page-header-parent > .container-max.cfour-cachet {
  top: 0 !important;
  position: relative !important;
}

.page-home-category {
  .carousel-item {
    max-width: 100%;
    img {
      width: 100%;
    }
  }
  .featured-category img {
    width: 100%;
  }
}

.main-navbar-collapsable ul.mainmenu>li>ul {
  display: block;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  height: auto;
  text-align: center;
  width: auto;
  max-width: 25em;
  min-width: 100%;
  word-wrap: break-word;
  position: absolute;
  z-index: 1;
}

.main-navbar-collapsable .row.justify-content-center {
  position:relative;
  width: 100%;
}

.back-to-top {
  margin: 0;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 40px;
  height: 40px;
  z-index: 100;
  display: none;
  text-decoration: none;
  opacity: 0.5;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  border: 1px solid #282d2f;
  background: #4D4F4D;
  border-radius: .1rem;
  cursor: pointer;
  text-align: center;
  i {
    font-size: 29px;
    color: #f7f7f9;
  }
}

@media (max-width: 991px) {
  .item-filter-price {
    flex-direction: column;
    .input-group {
      margin-bottom: 0.75rem;
    }
  }
}


.list-controls .filter-collapse .page-content {
  margin: 0.5rem 7.5px;
}

.breadcrumbs {
  background-color: $cfourWhite;
  ul {
    background-color: $cfourWhite;
  }
}


.badge, .tag {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .1rem;
  -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

/*.page-login, .page-confirmation {
  .main {
    margin-top: 6rem;
  }
}*/

/*.main-navbar-collapsable ul.mainmenu li:hover {
  margin-bottom: -1px;
}*/

.home a.hero-category img {
  width: 100%;
}

.top-bar {
  background: $cfourPrimaryColor;
}

.main-navbar-collapsable .mainmenu>li>a {
  color: $cfourPrimaryColor;
  &:hover {
    color: $cfourPrimaryColor;
  }
}

.cmp {
  &.cmp-country-settings, &.cmp-currency-select {
    color: #fff;
    border-bottom: 0;
    z-index: 1070;
    position: fixed;
    top: 30px;
    width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: $cfourPrimaryColor;
    ul li {
      display: inline-block;
      float: left;
      width: 16.666667%;
      a {
        color: $cfourWhite;
      }
    }
  }
  &.cmp-search-box {
    background: $cfourPrimaryColor;
  }
}


@media (max-width: 767px) {
  .top-bar-items .control-user a > span {
    display: none !important;
  }
}

html .top-bar .controls-list li .basket-preview-footer .btn-outline-primary {
  color: $cfourPrimaryColor;
}

.footer .to-top, .to-top {
  display: block;
  background-color: $cfourPrimaryColor;
  text-align: center;
  padding: .5rem 1rem;
  text-decoration: none!important;
  cursor: pointer;
  width: 100%;
  color: #fff;
  font-size: 1.5rem;
}
