.add-item {
  .fa {
    color: $cfourSecondaryColor;
    border-color: $cfourSecondaryColor;
    &:hover {
      color: $cfourSecondaryColor;
      border-color: $cfourSecondaryColor;
    }
  }
}

.cmp-address-list {
  .item-content {
    color: #000;
  }
  .item-remove, .item-edit {
    .btn {
      padding: 5px;
    }
  }
  .item input:checked {
    &+ .item-inner {
      background-color: $cfourSecondaryColor;
      color: $cfourWhite;
    }
  }
}