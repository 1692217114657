.top-bar {
	background: $cfourSecondaryColor;
	.cfour-cachet {
		position: relative !important;
		.cachet {
			position: absolute;
			left: 0;
			z-index: 999;
			top: 0;
			background: #fff;
			box-shadow: 0 3px 17px rgba(0,0,0,0.1);
			padding: 15px 0 15px 15px;
			width: auto;
			height: 110px;
			.image {
				width: 80px;
				height: 80px;
				background-size: cover !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				display: inline-block !important;
				margin-right: 15px;
			}
			img {
				border-style: none;
				width: 100px;
				padding-right: 15px;
			}
			@media (max-width: 575px) {
				display: grid;
				height: auto !important;
				max-height: 160px;
				.image {
					margin-right: 10px;
				}
			}
		}
	}
	.controls-list {
		.control-user {
		  #login-change {
			.dropdown-menu {
			  a {
				color: $cfourPrimaryColor;
				  background-color: $cfourWhite;
				  &:hover {
					  background-color: $cfourWhite;
				  }
			  }
			}
			  & > .dropdown.open {
				  & > .dropdown-toggle {
					  background-color: $cfourWhite;
				  }
			  }
			  @media (max-width: 767px) {
				  a span {
					display: none;
				  }
				  #accountMenuList i {
					  display: inline-block !important;
				  }
			  }
		  }
		}
		.control-languages {
			a[aria-expanded="true"] {
				background: $cfourSecondaryColor;
				color: #FFFFFF;
			}
		}
		.control-search {
			a[aria-expanded="true"] {
				background: $cfourSecondaryColor;
			}
		}
		.control-basket {
			.toggle-basket-preview {
				background-color: $cfourTertiaryColor;
				color: $cfourWhite;
				&:hover {
					background-color: $cfourSecondaryColor;
				}
			}
		}
		li{
		  a{
			font-size: .9em;
			color: $cfourWhite;
			text-transform: uppercase;
			&:hover {
			  background: $cfourSecondaryColor;
			  color: $cfourPrimaryColor;
			}
		  }
		  a[aria-expanded="true"] {
			background: $cfourSecondaryColor;
			font-size: .9em;
			color: #FFFFFF;
			text-transform: uppercase;
		  }
		}
		&>li{
			a{
				font-size: .9em;
				color: $cfourWhite;
				text-transform: uppercase;
				min-height: 30px;
				outline: none;
				&:hover {
					background: $cfourSecondaryColor;
					color: $cfourWhite;
				}
			}
			a[aria-expanded="true"] {
			  background: $cfourSecondaryColor;
			  font-size: .9em;
			  color: $cfourPrimaryColor;
			  text-transform: uppercase;
			}
		}
	}
	.brand-wrapper {
		position: relative;
		transform: none;
		text-align: center;
		display: flex;
		.navbar-brand {
			margin: 0 auto;
			max-width: 200px;
			padding: 20px 0;
			img {
				max-height: none;
			}
		}
		.navbar-toggler {
			position: absolute;
			right: 0.5rem;
			top: 3rem;
		}
	}
}
.navbar {
	background: $cfourWhite;
	border-bottom: none;
}


@media (max-width: 354px) {
	.control-basket i{
		display: none;
	}
	.control-wish-list .badge-right{
		display: none;
	}
}

@include media-breakpoint-down(md) {
	.top-bar {
		.cfour-cachet {
			.cachet {
				top: 0px;
				padding: 7px 0 7px 7px;
				width: auto;
				height: 55px;
				.image {
					width: 40px;
					height: 40px;
				}
				img {
					border-style: none;
					width: 40px;
					padding-right: 10px;
				}
			}
		}
	}
}
